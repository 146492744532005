<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col><div>Customer/Student</div></b-col>
            <b-col>
              <div align="right">
                <b-button
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  @click="ModalShow()"
                  v-if="allowCreate()"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>
              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="sm"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-form-group label="Nama" label-for="nama">
                      <b-form-input
                        id="nama"
                        v-model="form.name"
                        placeholder="Nama"
                      />
                    </b-form-group>
                    <b-form-group label="Email" label-for="email">
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        placeholder="Email "
                      />
                    </b-form-group>
                    <b-form-group label="Nomor Telepon" label-for="no_telp">
                      <b-form-input
                        type="number"
                        id="no_telp"
                        v-model="form.no_telp"
                        placeholder="Nomor Telepon"
                      />
                    </b-form-group>
                    <b-form-group label="Level" label-for="level">
                      <b-form-select
                        v-model="form.level_id"
                        :options="levels"
                        label="Level"
                        placeholder="Level"
                      />
                    </b-form-group>
                    <b-form-group label="Password" label-for="password">
                      <b-form-input
                        type="password"
                        id="nama"
                        v-model="form.password"
                        placeholder="Password"
                      />
                    </b-form-group>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click="Modal = false"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="data"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(icon)="data">
                  <feather-icon :icon="data.value" class="align-middle" />
                  {{ data.value }}
                </template>
                <template #cell(status)="{ item }">
                  <i>
                    <strong
                      :class="
                        isAccountInactive(item.status)
                          ? 'text-danger'
                          : 'text-success'
                      "
                    >
                      {{ isAccountInactive(item.status) ? "Belum" : "Sudah" }}
                    </strong>
                  </i>
                </template>
                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="allowUpdate()"
                      :to="{
                        name: 'admin-pengguna-customer-edit',
                        params: { id: row.item.id },
                      }"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="isAccountInactive(row.item.status)"
                      @click="activeAccount(row.item)"
                    >
                      <feather-icon icon="CheckCircleIcon" class="mr-50" />
                      <span>Aktivasi Akun</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="allowDelete()"
                      @click="ModalHapus(row.item)"
                    >
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <!-- A custom formatted column -->
                <template #cell(Popularity)="data">
                  <b-progress
                    :value="data.value.value"
                    max="100"
                    :variant="data.value.variant"
                    striped
                  />
                </template>

                <template #cell(order_status)="data">
                  <b-badge pill :variant="data.value.variant">
                    {{ data.value.status }}
                  </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(price)="data">
                  {{ "$" + data.value }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BDropdownItem,
    BDropdown,
    ToastificationContent,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      data: [],
      levels: [],
      form: {
        name: null,
        email: null,
        no_telp: null,
        level_id: 1,
        password: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "type_member", label: "Tipe Member", sortable: true },
        {
          key: "status",
          label: "Aktivasi Akun",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "action",
          label: "#",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Nama" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    isAccountInactive(status) {
      return status == 0 ? true : false;
    },
    activeAccount(user) {
      const { email, id: userId } = user;
      this.$swal({
        title: "Anda Yakin?",
        text: `Dengan diaktiviasi, Akun ${email} akan dapat mengakses aplikasi`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            await this.$store.dispatch(
              "auth-admin/verifyAccountManual",
              userId
            );
            this.displaySuccess({
              message: `Akun ${email} berhasil diaktivasi`,
            });
            this.getData();
            this.loading = false;
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    resetForm() {
      this.form.name = null;
      this.form.email = null;
      this.form.no_telp = null;
      this.form.level_id = 1;
      this.form.password = null;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.resetForm();
      this.activeAction = "tambah";
      this.titleModal = "Tambah Pengguna";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Pengguna";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("pengguna/createUpdateDelete", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.name,
        email: this.form.email,
        no_telp: this.form.no_telp,
        level_id: this.form.level_id,
        password: this.form.password,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("pengguna/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.form.name = null;
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Pengguna berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const payload = {
        level_id: 1,
      };
      this.$store
        .dispatch("pengguna/index", payload)
        .then((response) => {
          this.loading = false;
          let data = response.data.data;
          this.data = data;
          this.totalRows = this.data.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    getDatalevel() {
      this.$store.dispatch("level/index", {}).then((response) => {
        let level = response.data.data;
        let arr = [1];
        let res = arr.map((id) => level.find((x) => x.id == id));
        res.map((item) => {
          item.value = item.id;
          item.text = item.nama_level;
        });
        this.levels = res;
      });
    },
  },
  created() {},
  async mounted() {
    this.getData();
    this.getDatalevel();
  },
};
</script>

<style></style>
